


















































































































































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import { CommonController } from '@/services/request.service'
import LazyImage from '@/components/LazyImage.vue'
import shareStudentsIcon from '@/assets/images/OtherApp/Moment/share_students.svg?inline'
import shareParentsIcon from '@/assets/images/OtherApp/Moment/share_parents.svg?inline'
import translateIcon from '@/assets/images/OtherApp/Moment/translate.svg?inline'

@Component({
  components: {
    LazyImage,
  },
})
export default class momentDetialModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly data!: any
  @Prop({ default: false }) private isDeleted!: boolean
  @Prop({ default: false }) private loading!: boolean
  private translationVisible = false
  private options: any = {
    url: 'data-source',
  }
  private actions: Array<any> = [{ type: 'like', text: '' }]

  private shareStudentsIcon = shareStudentsIcon
  private shareParentsIcon = shareParentsIcon
  private translateIcon = translateIcon

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  private avatarFilter(students: Array<any>): Array<any> {
    const newStudents = (students || []).slice(0, 3)
    const avatars = newStudents.map(student => student.studentAvatarUrl)
    return avatars
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      if (this.data.expandClickShow) return
      this.$set(this.data, 'expandClickShow', false)
      let el = document.getElementById(`momentTextContent_sys`)
      if (!el) return
      let cw = el.clientWidth
      let sw = el.scrollWidth
      if (sw > cw) {
        this.$set(this.data, 'expandClickShow', true)
      }
    })
  }

  private updated() {
    this.calcExpand()
  }

  private translate(item): void {
    if (item.translateVisible) {
      item.translateVisible = false
    } else {
      CommonController.translate(item.content).then(res => {
        this.translationVisible = true
        this.$set(item, 'translate', res.data.value)
      })
      item.translateVisible = true
    }
  }
}
