





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { MessageController, MomentsController } from '@/services/request.service'
import momentDetailModal from './momentDetialModal.vue'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    momentDetailModal,
  },
})
export default class MessageViewer extends Vue {
  @Prop() private readonly type!: any
  private data: any = []
  private getAllDataFlag: boolean = false
  private momentDetailShow: boolean = false
  private momentIsDeleted: boolean = false
  private momentLoading: boolean = false
  private pagination: any = createPagination({ current: 0 })
  private momentDetail: any = {}
  private viewerTypeCode: any = {
    favor: 'Favor',
    approval: 'Approval',
    notice: 'System',
  }
  private toPageCode: any = {
    1609: 'myAdjustCurriculum',
    1620: 'adjustApproval',
    1622: 'leaveApproval',
    1626: 'myAdjustCurriculum',
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      queryType: this.viewerTypeCode[this.type],
    }
    MessageController.getToList(condition).then(res => {
      this.data = this.data.concat(
        res.data.items.map(item => ({
          ...item,
          sendTime: moment(item.sendTime).format('MM-DD HH:mm'),
        }))
      )
      if (this.type === 'favor') {
        this.data.map(item => {
          let enterIndex = item.content.indexOf('\n')
          const momentName = item.content.slice(0, enterIndex)
          const momentContent = item.content.slice(enterIndex + 1, (item.content || '').length)
          this.$set(item, 'momentName', momentName)
          this.$set(item, 'momentContent', momentContent)
        })
      }
      this.pagination.total = res.data.totalItem
      if (this.data.length === this.pagination.total) {
        this.getAllDataFlag = true
      }
    })
  }

  private handleInfoLoad(): void {
    this.pagination.current++
    this.getData(this.pagination)
  }

  @Watch('type', { immediate: true })
  private onTypeChange(val) {
    if (val) {
      this.clearData()
      this.handleInfoLoad()
    }
  }

  private toPage(type): void {
    if (!type) return
    this.$router.push({ name: this.toPageCode[type] })
  }

  private get userId(): any {
    return this.$store.state.memberId
  }

  private clearData(): void {
    this.data = []
    this.pagination = createPagination({ current: 0 })
    this.getAllDataFlag = false
  }

  private showMomentDetail(message): void {
    let momentId = message.masterRecordId
    this.momentDetailShow = true
    this.momentDetail = {}
    this.momentLoading = true
    MomentsController.getMomentById(momentId)
      .then(res => {
        if (!res.data) {
          this.momentIsDeleted = true
          return
        }
        let isLike = res.data.favors.filter(item => item.userId === this.userId).length !== 0
        this.momentDetail = {
          ...res.data,
          date: moment(res.data.time).format('YYYY.MM.DD HH:mm'),
          isLike,
          translateVisible: false,
          allPhotoVisible: false,
          contentExpand: false,
        }
        if (!this.momentDetail) {
          this.$message.warning(this.$tc('tips.momentDeleted'))
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.momentLoading = false))
  }

  private getTitle(item): any {
    if (this.type !== 'notice') {
      const relation = item.fromMember.relationship
        ? this.$t('relation.' + item.fromMember.relationship)
        : ''
      return `${item.fromMember.enName} ${item.fromMember.name} ${relation}`.trim()
    } else {
      if (item.type === '1609') {
        return this.$t('systemMessage.auditSuccess')
      } else if (item.type === '1626') {
        return this.$t('systemMessage.auditFail')
      }
      return ''
    }
  }
}
